import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "chatbox-customization"
    }}>{`Chatbox customization`}</h1>
    <blockquote>
      <p parentName="blockquote">{`These settings need to be there when the script is run, chat client doesn't currently support adhoc changing of properties`}</p>
    </blockquote>
    <h2 {...{
      "id": "welcome-text"
    }}>{`Welcome text`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    welcomeTitle: 'Hello there!',
    welcomeText: 'That is a nice looking coat!'
  });
`}</code></pre>
    <h2 {...{
      "id": "agents-are-busy-texts-and-timing"
    }}>{`Agents are busy texts and timing`}</h2>
    <p>{`You can set the timing when the customer will get agents are busy message, this will prevent customers waiting in line for too long. This timer starts when customer sends first message and dissapears when agent joins the session. Use feedbackButtonDelay to set the delay to offer feedbackform and maxAgentWaitTime to force customer to use feedbackform. These timers are in seconds.
You can also customize the message the customer receives.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    waitingForAgentMsg: 'Our agents are busy, you can also',
    waitingForAgentBtn: 'send us a message!',
    feedbackButtonDelay: 60,
    maxAgentWaitTime: 120
  });
`}</code></pre>
    <h2 {...{
      "id": "increasedecrease-chatbox-or-fontsize"
    }}>{`Increase/Decrease chatbox or fontsize`}</h2>
    <p>{`You can enable increasing/decreasing of enduser chatbox/fontsize with adding new selector in the init script, these selectors are fontSizeSelector and chatSizeSelector.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note that you can use one of these, not both!`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    chatSizeSelector: true // OR
    fontSizeSelector: true
  });
`}</code></pre>
    <h2 {...{
      "id": "convert-all-links-to-a-clickable-link"
    }}>{`Convert all links to a clickable link`}</h2>
    <p>{`Currently chat converts only links that belong to the domains of the apikey into clickable links. Adding flag acceptAllUrls will convert any links into a clickable links which open in a new tab. This is done in order to prevent sending malicious links to customers in accident etc.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    acceptAllUrls: true
  });
`}</code></pre>
    <h2 {...{
      "id": "set-locale-of-chat-and-tab"
    }}>{`Set locale of chat and tab`}</h2>
    <p>{`You can set the locale at runtime by giving the script `}<inlineCode parentName="p">{`locale`}</inlineCode>{` property at runtime. Supported locales are `}<em parentName="p">{`eng`}</em>{`, `}<em parentName="p">{`fin`}</em>{`, `}<em parentName="p">{`swe`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    locale: 'eng'
  });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      